import { useAxiosWithAuth } from "../../utils/hooks/useAxiosAPI";
import useSWRMutation from "swr/mutation";
import store from "/redux/state";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 * @returns {Object} - The response from the API
 */
export default function usePromotionalCampaign(onJoin) {
  const axiosClient = useAxiosWithAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useSWRMutation(
    "user/promotional-campaigns/join",
    async (url, { arg: code }) =>
      await axiosClient.wrap(
        axiosClient.post(url, {
          code: code,
        })
      ),
    {
      onSuccess: (data) => {
        onJoin
          ? (onJoin(),
            store.setPopUp("JoinPromotionAuth", {
              title: t("promotionCode.joinSuccessTitle"),
              subtitle: t("promotionCode.joinSuccessSubtitle"),
              close: t("labels.okay"),
              close_callback: () => navigate("/promotional-campaigns"),
            }))
          : store.setPopUp("JoinPromotionAuth", {
              title: t("promotionCode.title"),
              subtitle: data.campaign.description,
              close: t("promotionCode.checkCampaigns"),
              close_callback: () => navigate("/promotional-campaigns"),
            });
      },
      onError: (res) => {
        store.setPopUp("JoinPromotionAuth", {
          title: res.message,
          close: t("promotionCode.checkCampaigns"),
          close_callback: () => navigate("/promotional-campaigns"),
        });
      },
    }
  );
}
