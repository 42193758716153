import styles from "./Popup.module.scss";
import PopupTemplate from "./PopupTemplates";

/**
 * @typedef {import('./Popup.type').PopupComponent} PopupComponent
 */

/** @type {PopupComponent} */

export default function Popup(props) {
  const { template, onClose, disclaimer, isOpen, ...state } = props;

  return (
    <div className={`${styles.overlay} ${isOpen && "open"}`}>
      <div
        className={styles.popup}
        {...(disclaimer && { style: { height: "100%", minHeight: "90vh" } })}
      >
        <button
          className={`close close_icon ${styles.close}`}
          onClick={onClose}
        />
        <PopupTemplate template={template} {...state} />
        {disclaimer && (
          <button onClick={disclaimer.action} className={styles.disclaimer}>
            {disclaimer.text}
          </button>
        )}
      </div>
    </div>
  );
}
