import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const searchParamsToTrack = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_content",
  "utm_term",
  "btag",
  "tfid", // dmission user id
  "tftarget", // dmission task name
  "code",
];

const ConversionParamsContext = createContext({
  utm_source: null,
  utm_medium: null,
  utm_campaign: null,
  utm_content: null,
  utm_term: null,
  btag: null,
  tfid: null,
  tftarget: null,
  code: null,
});

export function useConversionParams() {
  return useContext(ConversionParamsContext);
}

export default function ConversionParamsProvider({ children }) {
  const location = useLocation();
  const [context, setContext] = useState({
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_content: null,
    utm_term: null,
    btag: null,
    tfid: null,
    tftarget: null,
    code: null,
  });

  const setConversionParams = (searchParams) => {
    let newContext = {};
    searchParamsToTrack.forEach((param) => {
      const value =
        searchParams.get(param) || window.localStorage.getItem(param) || null;

      if (value) {
        window.localStorage.removeItem(param);
        window.localStorage.setItem(param, value);
      }

      newContext = {
        ...newContext,
        [param]: value || null,
      };
    });

    setContext(newContext);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setConversionParams(searchParams);
  }, [location]);

  return (
    <ConversionParamsContext.Provider value={context}>
      {children}
    </ConversionParamsContext.Provider>
  );
}
