/**
 * @typedef {import('./Popup.type').Popup} Popup
 */

/**
 * @typedef {{
 *   id: number,
 *   type: string,
 * }} PopupData
 */

import privatePassImg from "/assets/img/private_pass.png";

/**
 * Creates a popup configuration based on the popup type and data
 * @param {PopupData} data - The popup data from the API
 * @param {Function} t - Translation function
 * @param {Function} navigate - Navigation function
 * @param {Function} trigger - Function to mark notification as seen
 * @param {Function} hide - Function to hide the popup
 * @returns {Popup} Popup configuration object
 */
export function createPopup(data, t, navigate, trigger, hide) {
  const handleClose = () => {
    trigger({ id: data.id });
    hide();
  };

  const baseConfig = {
    isOpen: true,
    onClose: handleClose,
  };

  const popupTypes = {
    private_pass: {
      template: "image",
      image: privatePassImg,
      title: t("privatePassRewardPopup.title"),
      text: t("privatePassRewardPopup.text"),
      buttonText: t("privatePassRewardPopup.buttonText"),
      buttonLink: "/backpack",
    },
  };

  const typeConfig = popupTypes[data.type];

  const mergedConfig = {
    ...baseConfig,
    ...typeConfig,
  };

  // Add button action after merging to ensure we use the correct buttonLink
  mergedConfig.buttonAction = () => {
    if (mergedConfig.buttonLink) {
      navigate(mergedConfig.buttonLink);
    }
    handleClose();
  };

  return mergedConfig;
}
