import checkURL from "../../../utils/checkURL";
import styles from "./Popup.module.scss";

export default function PopupTemplate(props) {
  switch (props.template) {
    case "cover":
      return <TemplateCover {...props} />;
    case "video":
      return <TemplateVideo {...props} />;
    case "image":
      return <TemplateImage {...props} />;
    default:
      return <TemplateImage {...props} />;
  }
}

function Button({ text, action }) {
  return (
    <button className="def pr type-40 br-16" onClick={action}>
      {text}
    </button>
  );
}

function TemplateCover(props) {
  const { image, title, text, buttonText, buttonAction } = props;
  return (
    <div className={`${styles.wrapper} popup_cover`}>
      <img src={image} alt={title} className={styles.cover} />
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <p>{text}</p>
        <Button text={buttonText} action={buttonAction} />
      </div>
    </div>
  );
}

function TemplateVideo({ video, title, text, buttonText, buttonAction }) {
  return (
    <div className={`${styles.wrapper} popup_video`}>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.text}>{text}</p>
      </div>
      <video
        playsInline
        title={title}
        autoPlay
        controls
        src={checkURL(video)}
        className={styles.video}
      >
        <source src={checkURL(video)} type="video/webm" />
      </video>
      <Button text={buttonText} action={buttonAction} />
    </div>
  );
}

function TemplateImage({ image, title, text, buttonText, buttonAction }) {
  return (
    <div className={`${styles.wrapper} popup_image`}>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <p
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: text }}
        ></p>
      </div>
      <div className={styles.image}>
        <img src={image} alt={title} />
      </div>
      <Button text={buttonText} action={buttonAction} />
    </div>
  );
}
