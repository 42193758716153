import useSWR from "swr";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";
import { useContext } from "react";
import AuthContext from "/context/AuthProvider";

/**
 *
 * @returns {import("swr").SWRResponse<
 *   { vault: boolean, special_items: boolean, backpack: boolean },
 *   {},
 *   unknown,
 * >}
 */

export function useNewItemsIndicators() {
  const axiosClient = useAxiosWithAuth();
  const { auth } = useContext(AuthContext);

  return useSWR(
    auth?.token ? "user/backpack/tabs-new-items-indicators" : null,
    async (url) => {
      return await axiosClient.wrap(axiosClient.get(url));
    },
    {
      fallbackData: {
        vault: false,
        backpack: false,
        special_items: false,
      },
    }
  );
}
