import { create } from "zustand";

/**
 * @typedef {import('../components/core/Popups/Popup.type').Popup} Popup
 */

/**
 * @typedef {{
 *   popup: Popup,
 *   display: (payload: Popup) => void,
 *   hide: () => void
 * }} PopupStore
 */

/**
 * Initial state for the popup store
 * @type {Popup}
 */
const initialState = {
  isOpen: false,
  template: "image",
  onClose: () => {},
  disclaimer: null,
  state: null,
  title: "",
  text: "",
  buttonText: "",
  buttonAction: () => {},
};

/**
 * Zustand store for managing popup state and actions
 * @type {import('zustand').StoreApi<PopupStore>}
 */
const usePopupStore = create((set) => ({
  /** Current popup state */
  popup: initialState,

  /**
   * Displays a new popup with the provided configuration
   * @param {Popup} payload - The popup configuration to display
   */
  display: (payload) =>
    set(() => {
      return { popup: { ...payload } };
    }),

  /**
   * Hides the current popup and resets to initial state
   */
  hide: () =>
    set(() => {
      return { popup: { ...initialState } };
    }),
}));

export { usePopupStore };
