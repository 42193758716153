import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

// Maintain the timeout reference outside the component to avoid issues with re-renders
let copyTimeout = null;

/**
 * Copy address component with customizable color and size
 * @param {Object} props - Component props
 * @param {string} props.text - Text to copy
 * @param {string} [props.color="white"] - Icon color (default is white)
 * @param {string} [props.size="normal"] - Icon size ("small" or "normal")
 * @param {number} [props.copyDuration=1500] - Duration in ms for the copy feedback
 * @returns {JSX.Element} CopyAddress component
 */
export function CopyAddress({
  text,
  color = "white",
  size = "normal",
  copyDuration = 1500,
}) {
  const [copy, setCopy] = useState(false);

  const onCopy = () => {
    copyTimeout && clearTimeout(copyTimeout);
    setCopy(true);
    copyTimeout = setTimeout(() => setCopy(false), copyDuration);
  };

  const colorStyle = {
    backgroundColor: `var(--color-${color})`,
  };

  const sizeClass = size === "small" ? "icon-20" : "icon-24";

  return (
    <CopyToClipboard text={text} onCopy={onCopy}>
      <div
        className={`icon_copy ${sizeClass} ar-1${copy ? " copyed" : ""}`}
        style={copy ? null : colorStyle}
      ></div>
    </CopyToClipboard>
  );
}

export default CopyAddress;
