import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import fiat from "../../../assets/img/wallet/buy-fiat.png";
import crypto from "../../../assets/img/wallet/buy-ctypto.png";
import exchange from "../../../assets/img/wallet/buy-exchange.png";
import Exchange from "./components/Buy-Exchange";
import Fiat from "./components/buy-fiat/Buy-Fiat";
import Crypto from "./components/buy-crypto/Buy-Crypto";

import icon_gate from "/assets/img/tico/platform_gate.svg?url";
import icon_mexc from "/assets/img/tico/platform_mexc.svg?url";
import icon_bitmart from "/assets/img/tico/platform_bitmart.svg?url";
import AuthContext from "/context/AuthProvider.jsx";
import store from "/redux/state.js";
import { FiatPurchaseNotPossibleTooltip } from "/components/pop-ups/wallet-parts/components/FiatPurchaseNotPossibleTooltip.jsx";

const cryptoExs = [
  { icon: icon_gate, link: "https://www.gate.io/trade/TICO_USDT" },
  { icon: icon_mexc, link: "https://www.mexc.com/exchange/TICO_USDT" },
  {
    icon: icon_bitmart,
    link: "https://www.bitmart.com/trade/en-US?symbol=TICO_USDT&type=spot",
  },
];

const tabs = [
  {
    key: "fiat",
    src: fiat,
    alt: "wallet.buySection.methodSelection.fiatOption",
  },
  {
    key: "crypto",
    src: crypto,
    alt: "wallet.buySection.methodSelection.cryptoOption",
    disabled: true,
  },
  {
    key: "exchange",
    src: exchange,
    alt: "wallet.buySection.methodSelection.exchangeOption",
    disabled: false,
  },
];

const parts = {
  exchange: Exchange,
  fiat: Fiat,
  crypto: Crypto,
};

export default function Buy(props) {
  const { t } = useTranslation();
  const [activePart, setActivePart] = useState("");
  const { auth } = useContext(AuthContext);
  const { isMobile } = store.getState();

  function getDisabledTooltipMsg() {
    if (!auth.supportsFiatPurchase) {
      return t("wallet.buySection.labels.fiatPurchaseNotSupportedCountry");
    } else if (!auth.email) {
      return t("wallet.buySection.labels.fiatPurchaseNotSupportedEmail");
    }
    return null;
  }

  const updatedTabs = tabs.map((tab) => {
    if (tab.key === "fiat") {
      const fiatSupported = auth.supportsFiatPurchase && auth.email;
      return {
        ...tab,
        disabled: !fiatSupported,
        disabledTooltipMsg: !fiatSupported && getDisabledTooltipMsg(),
      };
    } else {
      return tab;
    }
  });

  const Component = parts[activePart];

  const changePart = (p = "") => {
    setActivePart(p);
  };

  return Component ? (
    <Component back={changePart} {...props} cryptoExs={cryptoExs} />
  ) : (
    <>
      <p className="text-center">
        {t("wallet.buySection.methodSelection.chooseMethod")}
      </p>
      <div className="buy-grid">
        {updatedTabs.map(({ key, ...tab }) =>
          key === "fiat" && tab.disabled && tab.disabledTooltipMsg ? (
            <FiatPurchaseNotPossibleTooltip
              triggerEventType={isMobile ? "touch" : "hover"}
              tab={tab}
              key={key}
              tabName={key}
              changePart={changePart}
            >
              <span>{tab.disabledTooltipMsg}</span>
            </FiatPurchaseNotPossibleTooltip>
          ) : (
            <button
              key={key}
              className="buy-grid--tab df fd-c text-center ai-c br-16"
              onClick={() => changePart(key)}
              disabled={tab.disabled}
            >
              <div className="df icon cover">
                <img src={tab.src} alt={t(tab.alt)} />
              </div>
              <span
                className="fixel_16_sbl"
                dangerouslySetInnerHTML={{ __html: t(tab.alt) }}
              ></span>
            </button>
          )
        )}
      </div>
    </>
  );
}
