import { useContext } from "react";
import useSWR from "swr";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";
import AuthContext from "/context/AuthProvider";
import { usePopupStore } from "/data/popupStore";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMarkNotificationAsSeen } from "./useMarkAsRead";
import { createPopup } from "/components/core/Popups/popupFactory";

/**
 * @typedef {{
 * description: "success",
 * data:
 *  [
 *    {
 *      id: number,
 *      title: string,
 *      type: string,
 *      text: string,
 *      button_name: string,
 *      buttonLink: string,
 *      video_url?: string,
 *      image_url?: string,
 *      metadata?: Record<string, any>
 *    }
 *  ]
 * }} Body
 * @return {import("swr").SWRResponse<Body, Error>}
 */
export function useGetAwaitingEvents() {
  const axiosClient = useAxiosWithAuth();
  const { auth } = useContext(AuthContext);
  const { popup, display, hide } = usePopupStore();
  const { trigger } = useMarkNotificationAsSeen();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return useSWR(
    () => (auth?.token ? ["popups?only_unread=1"] : null),
    ([url]) => axiosClient.wrap(axiosClient.get(url).then((res) => res)),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      onSuccess: (result) => {
        if (result?.data.length && !popup.isOpen) {
          const popupData = result.data[0];

          // not we just take the first one
          // and display "private_pass" popup
          // in the future we should distinguish
          // popups based on type
          const popupConfig = createPopup(
            { ...popupData, type: "private_pass" },
            t,
            navigate,
            trigger,
            hide
          );

          display(popupConfig);
        }
      },
    }
  );
}
