import { useEffect, useState, useContext } from "react";
import AuthContext from "/context/AuthProvider";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import * as Sentry from "@sentry/react";
import { decodeToken } from "react-jwt";

/** Timeout in milliseconds before retrying SSE connection */
const RETRY_TIMEOUT = 5000;
/** Base URL for Mercure SSE server */
const MERCURE_BASE = import.meta.env.VITE_REACT_APP_MERCURE_BASE_URL;

/**
 * Custom hook for handling Server-Sent Events (SSE) connections
 * @param {string} topic - The topic to subscribe to
 * @param {Function} handleTopicMessage - Callback function to handle incoming messages
 * @returns {boolean} Connection status (true if connected, false otherwise)
 *
 * @example
 * ```jsx
 * const MyComponent = () => {
 *   const handleMessage = (data) => {
 *     console.log('Received message:', data);
 *   };
 *
 *   const isConnected = useSSE('my-topic', handleMessage);
 *
 *   return <div>Connection status: {isConnected ? 'Connected' : 'Disconnected'}</div>;
 * };
 * ```
 */
const useSSE = (topic, handleTopicMessage = (data) => {}) => {
  const { auth } = useContext(AuthContext);
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (!auth?.sseToken) {
      return;
    }

    const messagesTopic = getTopicFromJWT(auth.sseToken, topic);

    let abortController = new AbortController();
    const connectMessageBroker = async () => {
      await fetchEventSource(`${MERCURE_BASE}?topic=${messagesTopic}`, {
        headers: {
          Authorization: `Bearer ${auth.sseToken}`,
        },
        signal: abortController.signal,
        onopen: async () => {
          setConnected(true);
        },
        onclose: () => {
          setConnected(false);
          setTimeout(connectMessageBroker, RETRY_TIMEOUT);
        },
        onerror: (error) => {
          Sentry.captureException("SSE error:", error);
          abortController.abort();
          setTimeout(connectMessageBroker, RETRY_TIMEOUT);
        },
        onmessage: (event) => {
          try {
            const message = JSON.parse(event.data);
            handleTopicMessage(message);
          } catch (error) {
            Sentry.captureException("Error parsing SSE event:", error);
          }
        },
      });
    };

    connectMessageBroker();

    return () => {
      abortController.abort();
    };
  }, [auth?.sseToken]);

  return connected;
};

export { useSSE };

/**
 * Extracts the topic from a JWT token
 * @param {string} token - The JWT token containing Mercure subscription information
 * @param {string} topicName - The topic name to find in the token
 * @returns {string} The full topic URL if found, empty string otherwise
 *
 * @example
 * ```javascript
 * const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9...";
 * const topic = getTopicFromJWT(token, "my-topic");
 * // Returns: "https://example.com/topics/my-topic"
 * ```
 */
function getTopicFromJWT(token, topicName) {
  if (typeof token !== "string" || !token.length) {
    return "";
  }
  const decodedTokenString = decodeToken(token);
  return decodedTokenString?.mercure?.subscribe.find((element) =>
    element.includes(topicName)
  );
}
