import useSWR from "swr";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";
import { useContext } from "react";
import AuthContext from "/context/AuthProvider";

/**
 * @typedef {{
  success: boolean,
  spins_balance: number,
  streak: number,
  has_daily_bonus: boolean,
  data: {
      daily_bonus_xp: number,
      daily_bonus_wheel_spins: number,
      streak_bonus_days_count: number,
      streak_bonus_wheel_spins: number,
      prizes: [
          {
              id: number,
              quantity: number,
              item_id: number,
              type: string,
              name: string,
              image: string
          }
      ]
  }
}} Body
 * @typedef {{ }} Error
 * @param {import("swr").SWRConfiguration<Body, Error, import("swr").BareFetcher<Body>>} config
 * @return {import("swr").SWRResponse<Body, Error>}
 */
export function useWheelOfFortuneConfiguration(config = {}) {
  const axiosClient = useAxiosWithAuth();
  const { auth } = useContext(AuthContext);

  return useSWR(
    auth?.token ? ["user/wheel-of-fortune"] : null,
    ([url]) => axiosClient.wrap(axiosClient.get(url).then((res) => res)),
    {
      keepPreviousData: true,
      ...config,
    }
  );
}
