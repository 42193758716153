import React, { useEffect } from "react";
import Ico from "/assets/img/icons/checkbox.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Checkbox({
  checked,
  title,
  handleChange,
  value,
  children = undefined,
  def,
  underline,
  dot,
  ...props
}) {
  const { t } = useTranslation();

  const agree = () => (
    <span className="txt">
      {t("registration.labels.agree.label")}{" "}
      <NavLink
        to="/terms-and-conditions.pdf"
        title={t("layout.menus.terms")}
        target="_blank"
      >
        {t("layout.menus.terms")}
      </NavLink>{" "}
      {t("registration.labels.agree.labelAnd")}{" "}
      <NavLink
        to="/privacy-policy"
        title={t("layout.menus.privacy")}
        target="_blank"
      >
        {t("layout.menus.privacy")}
      </NavLink>
    </span>
  );

  useEffect(() => {
    if (def) {
      const el = { target: { checked: true, ...props } };
      handleChange(el);
    }
  }, []);

  return (
    <>
      {underline && <div className="underline"></div>}
      <label
        className={`label checkbox ${value ? "active" : ""}`}
        title={title}
      >
        <div className="row">
          <input checked={value} onClick={handleChange} readOnly {...props} />
          <div
            className={`${
              dot ? `check-dot${value ? " active" : ""}` : "ico df br-8"
            }`}
          >
            {!dot && <Ico />}
          </div>
          {children ??
            (props.name == "agree" ? (
              agree()
            ) : (
              <span
                className="txt"
                dangerouslySetInnerHTML={{ __html: title }}
              ></span>
            ))}
        </div>
      </label>
    </>
  );
}
