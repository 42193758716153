import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "/components/core/labels/Input";

import CustomButton from "/components/core/ButtonDefault";
import { isValidAddress } from "/utils/utils";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";
import { Balance } from "./WithdrawableBalance";
import { BigDecimal } from "./money";
import Select from "/components/core/labels/Select";
import { useAvaliableChains } from "/api/cashier/withdrawal/available-chains";
import { useWithdrawableBalance } from "/api/cashier/balance";
import { useCashedTicoBalance } from "/api/user/tico-balance";
import DelayLink from "/components/core/DelayLink";
import { KYCStatus } from "/api/user/kyc-status";

// const defaultChains = [
//   {
//     chainId: 1,
//     chainName: "Test Avalanche 1",
//     chainIcon: null,
//     requiredConfirmations: 12,
//     addressType: null,
//   },
// ];

// not existed balance?.data?.withdrawal_kyc_limit
const REMOVE_AFTER_PHP_IN_PROD_TODO_KYC_LIMIT_TICO = 40000;
// not existed balance?.data?.withdrawal_fee
const REMOVE_AFTER_PHP_IN_PROD_TODO_KYC_FEE_TICO = 10;
/**
 *
 * @param {{
 *  changePart: (path: string, queryParams: import("./queryParams").WithdrawalQueryParams) => void,
 * data: import("./queryParams").WithdrawalQueryParams,
 * isUserKYC, changeTab, kycStatus, onClose }} props
 * @returns
 */
export default function Flow({
  changePart,
  isUserKYC,
  kycStatus,
  changeTab,
  data,
  onClose,
}) {
  const { t } = useTranslation();
  const [amountValue, setAmount] = useState(data.amountValue ?? "");
  const [addressValue, setWalletAddress] = useState(data.addressValue ?? "");
  const [isWalletOk, setIsWalletOk] = useState(null);

  const [isWithdrawAmountValid, setIsWithdrawAmountValid] = useState(null);

  const axiosClient = useAxiosWithAuth();

  // todo: pre-fetch all data before navigating
  const cachedBalance = useCashedTicoBalance();
  const balance = useWithdrawableBalance(axiosClient, {
    fallbackData: cachedBalance
      ? {
          balance: cachedBalance,
          withdrawableBalance: "?",
          blockedBalance: "?",
          withdrawalFee: "?",
          // it should be something until we know the real value
          withdrawalKycLimit: REMOVE_AFTER_PHP_IN_PROD_TODO_KYC_LIMIT_TICO,
          // it should be 0 until we know the real value
          minWithdrawalBeforeFee: 0,
        }
      : undefined,
    refreshInterval: 25000,
  });

  const getXXXEURTico = () => {
    if (isDefined(balance?.data?.withdrawalKycLimit)) {
      return +balance?.data?.withdrawalKycLimit;
    }
    if (REMOVE_AFTER_PHP_IN_PROD_TODO_KYC_LIMIT_TICO) {
      return REMOVE_AFTER_PHP_IN_PROD_TODO_KYC_LIMIT_TICO;
    }
    // just insane value
    return Number.MAX_SAFE_INTEGER - 100_000;
  };

  const isMoreThanXXXEUR = () => {
    const limit = getXXXEURTico();
    return amountValue && +amountValue > limit;
  };

  const handleAmountOnChange = (e) => {
    const val = e.target.value;
    handleAmountOnChangeVal(val);
  };

  const handleAmountOnChangeVal = (val) => {
    setAmount(val);
  };

  useEffect(() => {
    setIsWithdrawAmountValid(() => {
      const val = amountValue;
      if (balance?.data?.withdrawableBalance === "?") {
        return null;
      }
      if (balance?.data?.withdrawableBalance && val && !Number.isNaN(+val)) {
        const wdMinusFee = new BigDecimal(balance.data.withdrawableBalance);

        return val && new BigDecimal(val).lte(wdMinusFee);
      }
      return null;
    });
  }, [balance.data?.withdrawableBalance, amountValue]);

  const labelPlay = {
    placeholder: t("wallet.withdraw.flow.enterAmount"),
    name: "amount",
    type: "number",
    value: amountValue,
    autoComplete: "false",
    min: 0,
    isOk:
      isDefined(amountValue) && amountValue !== "0" && isWithdrawAmountValid,
    required: true,
    error: !isUserKYC && isMoreThanXXXEUR(),
    tip: { title: t("wallet.withdraw.flow.withdrawTip") },
    handleChange: (e) => handleAmountOnChange(e),
  };

  const labelAddress = {
    placeholder: t("wallet.withdraw.flow.enterWalletAddress"),
    name: "cryptoAddress",
    value: addressValue,
    id: "cryptoAddress",
    autoComplete: "false",
    description: t("wallet.withdraw.flow.addressNote"),
    required: true,
    error: isWalletOk == null ? "" : isWalletOk ? "" : " ",
    isOk: isWalletOk,
    minLength: 42,
    tip: { title: t("wallet.withdraw.flow.enterWalletAddress") },
  };

  const onAddressChange = (e) => {
    const v = e.target.value;
    setWalletAddress(v);
  };

  useEffect(() => {
    const v = addressValue;
    const isValid = v ? isValidAddress(v) : null;
    setIsWalletOk(isValid);
  }, [addressValue]);

  const hasWithdrawableBalance = () => +balance.data?.withdrawableBalance > 0;

  const availableChains = useAvaliableChains(axiosClient, {
    fallbackData: [], // todo: adjust to the real fallbackData
    revalidateOnMount: true, // to revalidate fallbackData
    keepPreviousData: true,
  });

  const chainList = useMemo(
    () =>
      (availableChains.data || [])
        .filter(({ chainId }) => chainId !== undefined && chainId !== null)
        .reduce((acc, { chainId, chainName }) => {
          acc[chainId] = chainName;
          return acc;
        }, {}),
    [availableChains.data]
  );

  const [selectedChain, setSelectedChain] = useState(data.chain?.chainId ?? "");

  const selectChainID = (e) => {
    setSelectedChain(e.target.value);
  };

  useEffect(() => {
    if (Object.keys(chainList).length > 0 && !selectedChain) {
      setSelectedChain(Object.keys(chainList)[0]);
    }
  }, [chainList, selectedChain]);

  return (
    <>
      <Balance {...balance} />
      <div className="buy-fiat df fd-c">
        <div className="df fd-c">
          <Input
            {...labelPlay}
            error={
              isDefined(amountValue) &&
              (isWithdrawAmountValid === false ||
                (isUserKYC && isMoreThanXXXEUR()))
            }
          >
            <p className="right-tip">$TICO</p>
          </Input>
          {isMoreThanXXXEUR() && (
            <KycError
              changeTab={changeTab}
              onClose={onClose}
              kycStatus={kycStatus}
            />
          )}
          {isDefined(amountValue) && isWithdrawAmountValid === false && (
            <p className="fixel_14 color-red mr-t-8">
              {t("wallet.withdraw.flow.withdrawAmountError")}
            </p>
          )}
        </div>

        <Counts
          onChange={(x) => {
            if (hasWithdrawableBalance()) {
              const val = new BigDecimal(balance.data?.withdrawableBalance).mul(
                x
              );
              console.log(balance.data?.withdrawableBalance, x, val);
              handleAmountOnChangeVal(val.toFixed(0));
            }
          }}
          disabled={!hasWithdrawableBalance()}
        />
        {(() => {
          if (availableChains.error) {
            return (
              <div>
                <p>{availableChains.error.message}</p>
                <CustomButton
                  onClick={() => availableChains.mutate()}
                  classname={`go-back grey as-c`}
                  def={40}
                  title={t("labels.retry")}
                />
              </div>
            );
          }
          if (!availableChains.data) {
            return (
              <Select
                handleChange={selectChainID}
                list={chainList}
                def={
                  selectedChain
                    ? chainList[selectedChain]
                    : t("wallet.deposit.selectChain")
                }
                value={selectedChain}
              />
            );
          }
          return (
            <Select
              handleChange={selectChainID}
              list={chainList}
              def={
                selectedChain
                  ? chainList[selectedChain]
                  : t("wallet.deposit.selectChain")
              }
              value={selectedChain}
            />
          );
        })()}
        <Input {...labelAddress} handleChange={onAddressChange} />
      </div>

      <div className="buttons df jc-c">
        <CustomButton
          onClick={() => {
            const chain = availableChains.data?.find(
              (x) => +x.chainId === +selectedChain
            );
            if (!chain) {
              console.error(
                "Chain not found",
                selectedChain,
                availableChains.data
              );
            }
            changePart("confirm", {
              amountValue,
              addressValue,
              chain: chain,
            });
          }}
          classname={`go-buy pr`}
          def={40}
          disabled={
            !hasWithdrawableBalance() ||
            !amountValue ||
            !addressValue ||
            amountValue === "0" ||
            !isWalletOk ||
            !chainList[selectedChain] ||
            !isWithdrawAmountValid ||
            (!KYCStatus.isUserVerified(kycStatus.data?.kyc_status) &&
              isMoreThanXXXEUR())
          }
          title={t("wallet.withdraw.flow.withdraw")}
        />
      </div>
    </>
  );
}

function isDefined(x) {
  return x !== undefined && x !== null && x !== "?" && x !== "";
}

/**
 * @type {[string, number][]}
 */
const counts = [
  ["25%", 0.25],
  ["50%", 0.5],
  ["75%", 0.75],
  ["MAX", 1],
];

/**
 *
 * @param {{ onChange: (x: number) => void, disabled: boolean }} param0
 * @returns
 */
function Counts({ onChange, disabled }) {
  // todo: isn't it optional radio button, and we should send it to the server?
  return (
    <div className="row-counts df w-full fixel_16_sbl">
      {counts.map(([label, value]) => (
        <button
          disabled={disabled}
          className="count df ai-c br-14"
          key={label}
          onClick={() => !disabled && onChange(value)}
        >
          <div className="w-full df jc-c">
            <p>{label}</p>
          </div>
        </button>
      ))}
    </div>
  );
}

function KycError({ kycStatus, changeTab, onClose }) {
  const { t } = useTranslation();
  return KYCStatus.isUserVerified(kycStatus.data?.kyc_status) ? (
    <></>
  ) : (
    <p className="fixel_14 kyc-error color-red mr-t-8">
      {t("wallet.withdraw.flow.kycRequirement")}{" "}
      {KYCStatus.isBanned(kycStatus.data?.kyc_status) ? (
        <></>
      ) : (
        <DelayLink
          to={"/profile/verification"}
          className="text-underline pointer"
          title={t("wallet.withdraw.flow.verifiedAccount")}
          cd={() => {
            setTimeout(onClose, 250);
          }}
        >
          {t("wallet.withdraw.flow.verifiedAccount")}
        </DelayLink>
      )}
      <br />
      {t("wallet.withdraw.flow.readTerms")}{" "}
    </p>
  );
}
