import React, { useState } from "react";
import RegistrationLabels from "/components/pop-ups/RegistrationLabels";
import { useTranslation } from "react-i18next";
import GiftBox from "/assets/img/promotion/starter-bag-lg.png";
import { NavLink } from "react-router-dom";
import styles from "./Registration.module.scss";
import WalletAuth from "/components/pop-ups/wallet-auth/WalletAuth";
import CustomButton from "/components/core/ButtonDefault";
import store from "/redux/state";
import { Web3Provider } from "/context/Web3Provider";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const tabs = [
  {
    title: "password",
    Component: RegistrationLabels,
  },
  {
    title: "wallet",
    Component: () => (
      <Web3Provider>
        <WalletAuth />
      </Web3Provider>
    ),
  },
];

export default function Registration({ data: { tab } }) {
  const isWalletConnectActive = useFeatureIsOn("can-use-auth-with-wallet");
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(
    () => tabs.find((entry) => entry.title === tab)?.title || "password"
  );

  return (
    <>
      <div className={`title ${styles.title}`}>
        <img src={GiftBox} alt="Claim Gift Box" className={styles.image} />
        <h2 className="t fixel_36">{t("message.welcomeToFuntico")}</h2>
      </div>
      {isWalletConnectActive ? (
        <TabList
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        >
          <TabContent>
            {tabs
              .filter(({ title }) => title === currentTab)
              .map(({ Component, title }) => (
                <Component key={title} />
              ))}
          </TabContent>
        </TabList>
      ) : (
        <RegistrationLabels />
      )}

      <div className="switch_form jc-c fw as-c df">
        <p className="fixel_16_sbl">{t("registration.alreadyHaveAccount")}</p>
        <button className="def wt" onClick={(e) => store.setPopUp("login")}>
          {t("registration.signInNow")}
        </button>
      </div>
    </>
  );
}

function TabList({ tabs, currentTab, setCurrentTab, children }) {
  const { t } = useTranslation();
  return (
    <div className={styles.tabs} role="tablist">
      <div className={`${styles.tabs_buttons} br-8`}>
        {tabs?.map(({ title }) => (
          <CustomButton
            key={title}
            role="tab"
            aria-selected={currentTab === title}
            classname={`grey ${currentTab === title ? styles.active : ""} ${
              styles.tab
            }`}
            title={t(`registration.tabs.${title}`)}
            def={24}
            onClick={() => setCurrentTab(title)}
          />
        ))}
      </div>
      {children}
    </div>
  );
}

function TabContent({ children }) {
  return <div className="content_data df fd-c">{children}</div>;
}
