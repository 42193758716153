import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

export function BalanceTooltipContent({ balanceResponse }) {
  const { t } = useTranslation();

  const usableBalance = useMemo(() => {
    return balanceResponse?.ticoBalance
      ? Intl.NumberFormat("en-US", {
          style: "decimal",
          maximumFractionDigits: 0,
        }).format(Number(balanceResponse.ticoBalance))
      : 0;
  }, [balanceResponse?.ticoBalance]);

  const withdrawableBalance = useMemo(() => {
    return balanceResponse?.withdrawableBalance
      ? Intl.NumberFormat("en-US", {
          style: "decimal",
          maximumFractionDigits: 0,
        }).format(Number(balanceResponse.withdrawableBalance))
      : null;
  }, [balanceResponse?.withdrawableBalance]);

  return (
    <div className="tge_balance">
      <div className="info br-8">
        <p className="fixel_16_sbl text-underline">
          {t("layout.header.balanceDetails.title")}
        </p>
        <p className="fixel_14 mr-t-8">
          {t("layout.header.balanceDetails.usable")}: {usableBalance} $TICO
        </p>
        <p className="fixel_14 color-neu">
          {t("layout.header.balanceDetails.withdrawable")}:{" "}
          {withdrawableBalance} $TICO
        </p>

        <p className="fixel_14 color-neu mr-t-20">
          {t("layout.header.balanceDetails.paragraph1")}
        </p>
        <p className="fixel_14 color-neu mr-t-16">
          {t("layout.header.balanceDetails.paragraph2")}
        </p>
        <p className="fixel_14 color-neu mr-t-16">
          {t("layout.header.balanceDetails.paragraph3")}
        </p>
      </div>
    </div>
  );
}
