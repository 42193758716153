import { useContext } from "react";
import useSWR, { useSWRConfig } from "swr";
import AuthContext from "/context/AuthProvider";
/**
 *
 * @return {null | string | undefined} Tico balance
 */
export function useCashedTicoBalance() {
  return useSWRConfig().cache.get("/user/tico-balance")?.data;
}
/**
 * @typedef {{ balance: string, withdrawableBalance: string, blockedBalance: string, ticoBalance: string }} Body
 * @typedef {{ }} Error
 * @param {import("axios").AxiosInstance} axiosClient
 * @param {import("swr").SWRConfiguration<Body, Error, import("swr").BareFetcher<Body>>} config
 * @return {import("swr").SWRResponse<Body, Error>}
 */
export function useWithdrawableBalance(axiosClient, config = {}) {
  const { auth } = useContext(AuthContext);

  return useSWR(
    auth?.token ? "user/tico-balance" : null,
    (url) => axiosClient.get(url).then((res) => res.data),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      ...config,
    }
  );
}
