import useSWR from "swr";

/**
 * @typedef {{ balance: string, withdrawableBalance: string, blockedBalance: string, withdrawalFee: string, minWithdrawalBeforeFee: number, withdrawalKycLimit: number }} Body
 * @typedef {{ }} Error
 * @param {import("axios").AxiosInstance} axiosClient
 * @param {import("swr").SWRConfiguration<Body, Error, import("swr").BareFetcher<Body>>} config
 * @return {import("swr").SWRResponse<Body, Error>}
 */
export function useWithdrawableBalance(axiosClient, config = {}) {
  return useSWR(
    () => ["cashier/balance"],
    ([url]) => axiosClient.get(url).then((res) => res.data),
    {
      keepPreviousData: true,
      ...config,
    }
  );
}
