import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import store from "/redux/state";
import AuthContext from "/context/AuthProvider";

const DelayLink = ({
  children,
  to,
  need_auth = false,
  cd = undefined,
  myRef = null,
  ...props
}) => {
  let navigate = useNavigate();
  const { auth } = useContext(AuthContext);

  const handleClick = (e) => {
    cd && cd(e);
    if (location?.pathname === to) return;
    if (e.defaultPrevented || props?.target == "_blank") return;

    e.preventDefault();

    if (need_auth && !auth.token) {
      store.setPopUp("login");
      return;
    }

    if (props?.disabled) return;
    navigate(to);
  };

  return to ? (
    <NavLink to={to} onClick={handleClick} {...props} end ref={myRef}>
      {children}
    </NavLink>
  ) : (
    <div {...props}>{children}</div>
  );
};

export default DelayLink;
