import useSWRMutation from "swr/mutation";
import store from "/redux/state";
import { useContext } from "react";
import AuthContext from "/context/AuthProvider";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";

/**
 * Hook for handling wallet authentication
 * @typedef {{
 *   wallet: string,
 *   signature: string,
 *   message: string,
 *   name?: string,
 *   language_id?: string,
 *   btag?: string,
 *   page?: string
 * }} AuthData
 * @typedef {{
 *   success: boolean,
 *   token?: string,
 *   pid?: number
 * }} Response
 * @typedef {{
 *   message?: string
 *   code?: "terms_not_accepted_or_not_above_required_age" | "internal_server_error" | "invalid_wallet_signature" | "validation_failed" | "user_removed_or_suspended" | "user_not_found" | "account_already_verified" | "verify_link_already_send" | "please_complete_the_verification_check_your_email" | "account_is_not_locked" | "incorrect_token" | "invalid_or_expired_url" | "user_not_found_or_hash_expired" | "invalid_credentials" | "disposable_email_not_allowed" | "unable_to_register_user"
 * }} Error
 * @returns {import("swr/mutation").SWRMutationResponse<Response, Error>} SWR mutation response
 */
export function useWalletAuth() {
  const axiosClient = useAxiosWithAuth();
  const { setAuth } = useContext(AuthContext);

  return useSWRMutation(
    "auth/wallet",
    async (_, { arg: authData }) => {
      const response = await axiosClient.wrap(
        axiosClient.post("auth/wallet", authData)
      );

      return response;
    },
    {
      onSuccess: (data) => {
        if (data?.success) {
          setAuth({ ...data });
          store.setPopUp(false);
        }
      },
    }
  );
}
