import React, { useEffect } from "react";
import { useAccount, useSignMessage } from "wagmi";
import { useTranslation } from "react-i18next";
import { ConnectKitButton } from "connectkit";
import CustomButton from "/components/core/ButtonDefault";
import WalletIcon from "/assets/img/icons/wallet.svg";
import { useWalletAuth } from "/api/auth/auth";
import store from "/redux/state";

const generateAuthMessage = () => ({
  timestamp: Math.floor(Date.now() / 1000),
  nonce: crypto.randomUUID(),
});

export default function WalletLogin() {
  const { t } = useTranslation();
  const { address, isConnected } = useAccount();
  const { signMessageAsync } = useSignMessage();

  const {
    trigger: handleAuthRequest,
    error: requestError,
    isMutating: isLoading,
  } = useWalletAuth();

  const handleSign = async () => {
    try {
      const message = generateAuthMessage();
      const messageStringified = JSON.stringify(message);
      const signature = await signMessageAsync({
        message: messageStringified,
        account: address,
      });

      await handleAuthRequest({
        wallet: address,
        signature,
        message: messageStringified,
      });
    } catch (err) {}
  };

  useEffect(() => {
    if (requestError?.code === "terms_not_accepted_or_not_above_required_age") {
      store.setPopUp("reg", { tab: "wallet" });
    }
  }, [requestError]);

  return (
    <div className="wallet-login df fd-c ai-c gap-16">
      {!isConnected ? (
        <ConnectKitButton.Custom>
          {({ show }) => (
            <CustomButton def={40} classname="pr" onClick={show}>
              <div className="df ai-c gap-8">
                <span>{t("labels.signInWith")}</span> <WalletIcon />
              </div>
            </CustomButton>
          )}
        </ConnectKitButton.Custom>
      ) : (
        <CustomButton
          def={40}
          classname="pr"
          onClick={handleSign}
          disabled={isLoading}
        >
          <div className="df ai-c gap-8">
            <WalletIcon />
            <span>
              {isLoading
                ? t("labels.loading")
                : `${t("labels.signInWith")} 0x...${address.slice(-4)}`}
            </span>
          </div>
        </CustomButton>
      )}
      {requestError && (
        <div className="error-form color-neu">
          {requestError?.code
            ? t(`serverErrorCode.${requestError.code}`)
            : t("message.somethingWentWrong")}
        </div>
      )}
    </div>
  );
}
