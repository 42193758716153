import useSWRMutation from "swr/mutation";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";

/**
 *
 * @returns {import("swr/mutation").SWRMutationResponse<
 *    {},
 *    {},
 *    unknown,
 *    { id: string | number }
 * >} SWR mutation response
 */
export function useMarkNotificationAsSeen() {
  const axiosClient = useAxiosWithAuth();

  return useSWRMutation("popups/{popupId}/mark-read", async (url, { arg }) => {
    const path = url.replace("{popupId}", arg.id.toString());
    return await axiosClient.wrap(axiosClient.post(path));
  });
}
