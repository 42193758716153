const erc20Regex = /^0x[a-fA-F0-9]{40}$/;

function isValidAddress(walletAddress) {
  return erc20Regex.test(walletAddress);
}

async function checkSearchParams(
  params,
  fetch,
  state,
  auth,
  pathname,
  navigate,
  getUser,
  t
) {
  const locationParams = new URLSearchParams(location.hash.substring(1));
  const [searchParams, setSearchParams] = params;
  const { fetchAPI } = fetch;
  const hash = searchParams.get("hash");
  const id = searchParams.get("id");
  const nftHash = searchParams.get("nftWithdrawalHash");
  const openRegistationForm = searchParams.get("openRegistationForm");
  const openRegistrationForm2 = searchParams.get("openRegistrationForm");
  const openLoginForm = searchParams.get("openLoginForm");
  const dMissionUserId = searchParams.get("tfid");
  const dMissionTaskId = searchParams.get("tftarget");
  const galxe = searchParams.get("galxe");
  const discordCode = searchParams.get("code");
  const telegramdCode = locationParams.get("tgAuthResult");
  const connectLuckyFunaticCode = searchParams.get("lf_connect");
  const confirmTicoithdrawalCode = searchParams.get("confirm-withdrawal");

  const clear = () => setSearchParams("");

  const getVerifyStatus = async () => {
    const data = new FormData();

    const info = {
      title: t("message.somethingWentWrong"),
      close: t("labels.okay"),
    };

    data.append("id", id);
    data.append("hash", hash);
    const d = await fetchAPI.post("auth/verify", data);
    const { message, success } = d;

    if (d?.status == "already_verified") {
      info.title = t("message.thanksForBeingPartOfFunticoFamily");
      info.subtitle = t("message.youAlreadyHaveAccount");
      clear();
    }

    if (d?.hadTempEmail) {
      info.title = t("message.emailPendingForVerificationVerified");
      info.subtitle = t("message.emailPendingForVerificationVerifiedMsg");
      clear();
    } else if (d?.data?.is_marketing_communication_allowed || d?.data?.email) {
      info.title = t("message.gamerOnBoard");
      info.subtitle = t("message.nowYouCanLogIn");
      clear();
    }
    const showLoginPopup =
      !auth.success &&
      (d?.data?.is_marketing_communication_allowed || d?.data?.email);
    if (showLoginPopup) {
      info.close_callback = () => {
        setTimeout(() => state.setPopUp("login"), 500);
      };
    }

    if (d?.message) {
      info.subtitle = d.message;
    }

    state.setPopUp("confirm", { ...info });
  };

  const getWithdrawal = async () => {
    const data = new FormData();

    const info = {
      title: t("message.nftRequestConfirmed"),
      subtitle: t("message.nftRequestConfirmedSubtitle"),
      close: t("labels.okay"),
    };

    data.append("hash", nftHash);
    const d = await fetchAPI.post("user/backpack/confirm-withdrawal", data);
    const { message, success } = d;

    if (!success) {
      if (!success && message) {
        info.title = message;
        info.subtitle = null;
      }
    }

    clear();
    state.setPopUp("confirm", { ...info });
  };

  const confirmTicoWithdrawalHandler = async () => {
    const data = new FormData();

    const info = {
      title: t("message.withdrawalConfirmed"),
      subtitle: t("message.withdrawalConfirmedSubtitle"),
      close: t("labels.okay"),
    };

    data.append("code", confirmTicoithdrawalCode);
    const d = await fetchAPI.post(
      "cashier/withdrawal/confirm-withdrawal-request",
      data
    );
    const { message, success } = d;

    if (!success) {
      if (!success && message) {
        info.title = t(message);
        info.subtitle = null;
      }
    } else {
      clear();
    }

    state.setPopUp("confirm", { ...info });
  };

  if (confirmTicoithdrawalCode) {
    confirmTicoWithdrawalHandler();
  }

  const connectLuckyFunaticHandler = async () => {
    if (auth?.token) {
      const res = await fetchAPI.post("user/connect-lucky-funatic", {
        lf_connect: connectLuckyFunaticCode,
      });

      if (res.success) {
        state.setPopUp("confirm", {
          title: res.message,
          close: t("labels.okay"),
        });
      } else {
        state.setPopUp("confirm", {
          title: t("message.somethingWentWrong"),
          subtitle: res.message,
          close: t("labels.okay"),
        });
      }
      searchParams.delete("lf_connect");
      setSearchParams(searchParams);
    } else {
      state.setPopUp("login");
    }
  };

  const getSocialsAuth = async () => {
    const isDiscord = pathname.includes("/discord");
    const base = "user/social-connections/callback/";
    const goToPage = auth?.token ? "profile/settings" : "/";

    const onError = (d) => {
      const code = d?.message;
      let errorMessage =
        code && isServerErrorCode(code)
          ? t(`serverErrorCode.${code}`)
          : t("message.somethingWentWrong");
      state.setPopUp("confirm", {
        title: errorMessage,
        subtitle: t("message.tryAgain"),
        close: t("labels.okay"),
      });
    };

    const onSuccess = (title, connected = false) => {
      const connectedMsg = t("message.socialConnectConnected", { title });
      const successMsg = t("message.socialConnectSuccess", { title });

      const info = {
        title: connected ? connectedMsg : successMsg,
        close: t("labels.okay"),
      };
      state.setPopUp("confirm", { ...info });
      getUser();
    };

    if (isDiscord && discordCode) {
      navigate(goToPage, { replace: true });

      const url = base + "discord";
      const data = { code: discordCode };
      const d = await fetchAPI.post(url, data);

      if (d.success) {
        onSuccess("Discord");
      } else {
        if (d?.code == "already_connected") {
          onSuccess("Discord", true);
        } else {
          onError(d);
        }
      }
    }

    if (telegramdCode) {
      navigate(goToPage, { replace: true });

      const url = base + "telegram";
      const data = { tgAuthResult: telegramdCode };
      const d = await fetchAPI.post(url, data);
      if (d.success) {
        onSuccess("Telegram");
      } else {
        if (d?.code == "already_connected") {
          onSuccess("Telegram", true);
        } else {
          onError(d);
        }
      }
    }
  };

  connectLuckyFunaticCode && connectLuckyFunaticHandler();
  hash && id && getVerifyStatus();
  nftHash && getWithdrawal();

  if ((openRegistationForm || openRegistrationForm2) && !auth?.token) {
    state.setPopUp("reg", { tab: openRegistrationForm2 });
  }

  if (openLoginForm && !auth?.token) {
    state.setPopUp("login");
  }

  if (
    dMissionUserId &&
    dMissionTaskId === "98b3eed8-c55e-410e-a3a6-7abfb8054abf"
  ) {
    if (!auth?.token) {
      state.setPopUp("login");
    } else {
      try {
        await fetchAPI.get(
          `user/dmission/track/login/${dMissionUserId}/${dMissionTaskId}`
        );
      } catch (error) {}
    }

    if (galxe) {
      try {
        await fetchAPI.get("user/galxe/track/registration");
      } catch (error) {}
    }
  }

  // check is need to confirm soc auth
  (pathname.includes("socials") || telegramdCode) && getSocialsAuth();
}

function convertDate(d) {
  const date = new Date(d);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);
  return `${day}.${month}.${year}`;
}

const isExternalLink = (url) => {
  const props = { to: url };

  if (url) {
    const origin = location.origin;
    if (url.startsWith(origin)) {
      props.to = url.split(origin).pop();
    } else {
      props["target"] = "_blank";
    }
  }

  return props;
};

function processMediumObject(item) {
  const { title, link, description, pubDate } = item;

  const imgRegex = /<img.*?src="(.*?)"/;
  const imgMatch = imgRegex.exec(description);
  const thumbnail = imgMatch ? imgMatch[1] : null;
  // const textWithoutHTML = description.replace(/<[^>]*>?/gm, "");
  // const sentences = textWithoutHTML.match(/[^.!?]+[.!?]+/g) || [];
  // const summary = sentences.slice(0, 1).join(" ");

  const date = new Date(pubDate).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return { title, thumbnail, date, link };
}

const payToLocaleString = (num) => {
  const formatted = Number(num).toLocaleString("en-US");
  return formatted;
};

const formatUserDate = (dateString) => {
  if (!dateString) return "";

  const date = new Date(dateString);
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const year = String(date.getUTCFullYear()).slice(-2);

  return `${month}.${day}.${year}`;
};
const isServerErrorCode = (code) => {
  return /^[a-z0-9_]+$/.test(code);
};

export {
  isValidAddress,
  checkSearchParams,
  convertDate,
  isExternalLink,
  processMediumObject,
  payToLocaleString,
  formatUserDate,
  isServerErrorCode,
};
