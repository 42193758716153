import React, { useCallback, useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { gsap, ScrollTrigger } from "gsap/all";
import { useTranslation } from "react-i18next";

import FunticoLogo from "/components/core/Logo";
import UnLoginedButton from "/components/core/HeaderNavButtons";
import { useContext } from "react";
import AuthContext from "/context/AuthProvider";
import DelayLink from "/components/core/DelayLink";
import Wallet from "/assets/img/icons/wallet.svg";

import UserAvatar from "/components/core/UserAvatar";
import TopBanner from "./TopBanner/TopBanner";
import CustomButton from "/components/core/ButtonDefault";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";
import { useWithdrawableBalance } from "/api/user/tico-balance";
import { BalanceTooltipContent } from "../core/BalanceTooltipContent";
import { usePopper } from "react-popper";

gsap.registerPlugin(ScrollTrigger);

const CashierWidget = ({ state, isWidgetActive, isMobile }) => {
  const { t } = useTranslation();
  const props = {
    title: isMobile ? null : t("layout.header.deposit"),
    def: isMobile ? 24 : 40,
    onClick: () => state.setShowWallet(true),
    classname: "pr wallet",
  };

  return isWidgetActive ? (
    <CustomButton {...props}>{isMobile && <Wallet />}</CustomButton>
  ) : null;
};

export default function Header({ isMobile, openNav, state, isWidgetActive }) {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const headerRef = useRef(null);
  const { pathname } = useLocation();
  const isProfile = pathname.includes("profile");
  const showNavigation = state.getState().showNavigation;
  const axiosClient = useAxiosWithAuth();
  const { data: balanceResponse } = useWithdrawableBalance(axiosClient);

  useEffect(() => {
    const h = headerRef?.current;
    if (!h) return;
    window["header"] = h;
    const resize = new ResizeObserver(() => ScrollTrigger.refresh());
    resize.observe(document.body);
    return () => {
      resize.disconnect();
    };
  }, []);

  useEffect(() => {
    setTimeout(createScrollTrigger, 10);
  }, [showNavigation]);

  const createScrollTrigger = () => {
    if (!headerRef.current) return;
    const header = headerRef.current;

    ScrollTrigger.create({
      trigger: document.body,
      start: `top -5px`,
      // start: `top ${header.clientHeight * -1}px`,
      end: "bottom center",
      onToggle: (e) => header?.classList.toggle("blurred", e.isActive),
    });

    // return { s, ro };
  };
  return (
    <div className="header_wrapper">
      <header className="header" ref={headerRef}>
        <FunticoLogo />
        {showNavigation &&
          (!isMobile ? (
            <UnLoginedButton state={state} auth={auth}>
              <CashierWidget {...{ state, isWidgetActive, isMobile }} />
            </UnLoginedButton>
          ) : (
            <>
              <button
                className="mobile_open br-16 def grey"
                onClick={() => openNav(true)}
              >
                <div className="b df fd-c jc-c">
                  <span></span>
                  <span></span>
                </div>
              </button>

              {auth.token ? (
                isProfile ? (
                  <NavLink
                    to={"/profile"}
                    title={t("layout.header.profile")}
                    end={true}
                  >
                    <UserAvatar {...auth}>
                      {/* <div className="info"></div> */}
                    </UserAvatar>
                  </NavLink>
                ) : (
                  <DelayLink to={"/profile"} title={t("layout.header.profile")}>
                    <UserAvatar {...auth}>
                      {/* <div className="info"></div> */}
                    </UserAvatar>
                  </DelayLink>
                )
              ) : null}
            </>
          ))}
      </header>
      {showNavigation &&
        (isMobile && auth?.token ? (
          <div className="fixed_ballance df jc-sb ai-c">
            <div className="space_taker">{/* Just to not update css */}</div>
            <div className="df fd-c ai-c">
              <div className="bal df ai-c">
                <div className="tico_icon ico" />
                {balanceResponse?.balance}
              </div>
              <Tooltip>
                <BalanceTooltipContent balanceResponse={balanceResponse} />
              </Tooltip>
            </div>
            <CashierWidget {...{ state, isWidgetActive, isMobile }} />
          </div>
        ) : (
          <></>
        ))}
    </div>
  );
}

// TODO: Extract and refactor this component to be used in other places
/**
 * A tooltip component that displays content in a floating popup
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Content to display inside the tooltip
 * @param {string} [props.placement="bottom" | "top" | "left" | "right"] - Preferred placement of tooltip relative to trigger element
 * @returns {JSX.Element} Tooltip component
 */
export function Tooltip({ children }) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement] = useState(null);
  const [isHidden, setIsHidden] = useState(true);

  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: "top",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
        {
          name: "flip",
          options: {
            fallbackPlacements: ["bottom", "right", "left"],
            boundary: "clippingParents",
            padding: 8,
          },
        },
        {
          name: "preventOverflow",
          options: {
            boundary: "clippingParents",
            padding: 8,
            altAxis: true,
          },
        },
        {
          name: "computeStyles",
          options: {
            gpuAcceleration: true,
            adaptive: false,
          },
        },
        {
          name: "arrow",
          options: {
            padding: 5,
            element: arrowElement,
          },
        },
        {
          name: "hide",
          enabled: true,
        },
      ],
    }
  );

  const handleHide = useCallback(() => {
    setIsHidden(true);
  }, [setIsHidden]);

  const handleClick = useCallback(() => {
    setIsHidden((prev) => !prev);
  }, [setIsHidden]);

  useEffect(() => {
    if (popperElement) {
      popperElement.setAttribute("data-popper-reference-hidden", isHidden);
      update?.();
    }
  }, [popperElement, update, isHidden]);

  useEffect(() => {
    setIsHidden(true);
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <button
        type="button"
        className="balance_details_trigger color-neu fixel_14 df jc-l mr-t-4"
        ref={setReferenceElement}
        onClick={handleClick}
      >
        details
      </button>
      <div
        className="info_message"
        ref={setPopperElement}
        style={{
          ...styles.popper,
          ...(isHidden ? { display: "none" } : {}),
        }}
        {...attributes.popper}
      >
        <div className="wrapper br-8">
          <button
            type="button"
            className="close_icon close_tooltip"
            onClick={handleHide}
          />
          {children}
        </div>
      </div>
    </div>
  );
}
