import React from "react";
import { useSSE } from "/utils/hooks/useSSE";
import { usePopupStore } from "/data/popupStore";
import { mutate } from "swr";
import { useMarkNotificationAsSeen } from "/api/sse/useMarkAsRead";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createPopup } from "/components/core/Popups/popupFactory";

const EVENT_TO_FETCHER_KEY = {
  "user.kyc_status.updated": "user/kyc-status",
  "user.tico_balance.updated": "user/tico-balance",
  "user.xp.updated": "user/profile",
};

export function ServerSentEventsHandlers() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { trigger } = useMarkNotificationAsSeen();
  const { display, hide } = usePopupStore();

  useSSE("popups", handleTopicPopups);
  useSSE("messages", handleTopicMessages);

  function handleTopicMessages(message) {
    mutate(message?.event ? EVENT_TO_FETCHER_KEY[message.event] : null);
  }

  function handleTopicPopups(message) {
    // send confirmation for displayed popup

    const popupConfig = createPopup(
      { ...message.data, type: "private_pass" },
      t,
      navigate,
      trigger,
      hide
    );

    display(popupConfig);
  }

  return null;
}
