import useSWR from "swr";

/**
 * @typedef {{ address: string, memo: string|null, tag: string|null }} Body
 * @typedef {{ 'message': 'User has not completed KYC', 'code': 'full_kyc_required' }} Error
 * This address should be gained only after the user has selected the chain manually
 * It make sense to use trusted event to trigger this request, but for now it's fine
 * @param {import("axios").AxiosInstance} axiosClient
 * @param {string} selectedChain
 * @param {import("swr").SWRConfiguration<Body, Error, import("swr").BareFetcher<Body>>} config
 * @return {import("swr").SWRResponse<Body, Error>}
 */
export function useDepositAddress(axiosClient, selectedChain, config = {}) {
  // same until it's used
  return useSWR(
    () =>
      !selectedChain
        ? null
        : ["cashier/direct-deposit/deposit-address", selectedChain],
    ([url, chainId]) =>
      axiosClient.wrap(
        axiosClient.get(url, {
          params: { chainId },
        })
      ),
    config
  );
}
