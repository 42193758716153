import React, { useCallback, useEffect, useState } from "react";
import UserIco from "/assets/img/icons/user.svg";
import CustomButton from "/components/core/ButtonDefault";
import Arrow from "/assets/img/icons/arrow_right.svg";
import User from "/components/core/User";
import UserBalance from "/components/core/UserBalance";
import { useTranslation } from "react-i18next";
import { useWithdrawableBalance } from "/api/user/tico-balance";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";
import { BalanceTooltipContent } from "./BalanceTooltipContent";
import { usePopper } from "react-popper";
import DelayLink from "./DelayLink";

export default function UnLoginedButton({
  clas,
  state,
  auth,
  children,
  ...props
}) {
  const { t } = useTranslation();
  const axiosClient = useAxiosWithAuth();
  const { data: balanceResponse } = useWithdrawableBalance(axiosClient);

  const [isBalanceVisible, setIsBalanceVisible] = useState(true);
  const mobile = state.getState().isMobile;
  const openModal = ({ target }) => {
    state.setPopUp(target.dataset.open);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {auth.token ? (
          <div className="short_user_info df">
            <div className="to_profile df ai-c">
              <User
                auth={auth}
                shortInfo={true}
                mobile={mobile}
                info="1"
                state={state}
              />
              {!mobile && (
                <>
                  <button
                    className={`df check_bal ${isBalanceVisible ? "" : "hide"}`}
                    onClick={(e) => setIsBalanceVisible(!isBalanceVisible)}
                  >
                    <Arrow />
                  </button>
                  {isBalanceVisible ? (
                    <div className="df fd-c">
                      <DelayLink
                        to="/profile/history"
                        className="balance df fd-c fixel_16_md"
                      >
                        <p className="color-neu">
                          {t("layout.header.balance")}
                        </p>
                        <UserBalance />
                      </DelayLink>
                      <Tooltip triggerEventType="hover" placement="bottom">
                        <BalanceTooltipContent
                          balanceResponse={balanceResponse}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
            {children}
          </div>
        ) : (
          <div className="buttons_sing df ai-c">
            <CustomButton
              classname="in wt"
              data-open="login"
              onClick={openModal}
              title={t("layout.header.signIn")}
            >
              <div className="icon df br-8">
                <UserIco></UserIco>
              </div>
            </CustomButton>
            <span className="line"></span>
            <CustomButton
              classname="reg wt promo"
              data-open="reg"
              onClick={openModal}
              title={t("layout.header.register")}
            />
          </div>
        )}
      </div>
    </>
  );
}

// TODO: Extract and refactor this component to be used in other places
/**
 * A tooltip component that displays content in a floating popup
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Content to display inside the tooltip
 * @param {string} [props.triggerEventType="hover" | "click" | "touch"] - Event type to trigger tooltip ("hover" or "click")
 * @param {string} [props.placement="bottom" | "top" | "left" | "right"] - Preferred placement of tooltip relative to trigger element
 * @returns {JSX.Element} Tooltip component
 */
export function Tooltip({
  children,
  triggerEventType = "hover",
  placement = "bottom",
}) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement] = useState(null);

  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
        {
          name: "flip",
          options: {
            fallbackPlacements: ["bottom", "right", "left"],
            boundary: "clippingParents",
            padding: 8,
          },
        },
        {
          name: "preventOverflow",
          options: {
            boundary: "clippingParents",
            padding: 8,
            altAxis: true,
          },
        },
        {
          name: "computeStyles",
          options: {
            gpuAcceleration: true,
            adaptive: false,
          },
        },
        {
          name: "arrow",
          options: {
            padding: 5,
            element: arrowElement,
          },
        },
        {
          name: "hide",
          enabled: true,
        },
      ],
    }
  );

  const isHover = triggerEventType === "hover";

  const handleShow = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (popperElement) {
        popperElement.setAttribute("data-popper-reference-hidden", "false");
        update?.();
      }
    },
    [popperElement, update]
  );

  const handleHide = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (popperElement) {
        popperElement.setAttribute("data-popper-reference-hidden", "true");
        update?.();
      }
    },
    [popperElement, update]
  );

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (popperElement) {
        const currentValue = popperElement.getAttribute(
          "data-popper-reference-hidden"
        );
        popperElement.setAttribute(
          "data-popper-reference-hidden",
          currentValue === "true" ? "false" : "true"
        );
        update?.();
      }
    },
    [popperElement, update]
  );

  const handleClose = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (popperElement) {
        popperElement.setAttribute("data-popper-reference-hidden", "true");
        update?.();
      }
    },
    [popperElement, update]
  );

  let triggerHandlers = {};

  if (!isHover) {
    triggerHandlers = {
      onMouseEnter: () => {},
      onMouseLeave: () => {},
      onClick: handleClick,
    };
  } else {
    triggerHandlers = {
      onMouseEnter: handleShow,
      onMouseLeave: handleHide,
    };
  }

  useEffect(() => {
    if (popperElement) {
      popperElement.setAttribute("data-popper-reference-hidden", "true");
      update?.();
    }
  }, [popperElement, update]);

  return (
    <div style={{ position: "relative" }}>
      <button
        type="button"
        className="balance_details_trigger color-neu fixel_14 df jc-l mr-t-4"
        ref={setReferenceElement}
        {...triggerHandlers}
      >
        details
      </button>

      <div
        className="info_message"
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <div className="wrapper br-8">
          {!isHover && (
            <button
              type="button"
              className="close_icon close_tooltip"
              onClick={handleClose}
            />
          )}
          {children}
        </div>
      </div>
    </div>
  );
}
