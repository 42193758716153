import { createPopper } from "@popperjs/core";

let popperInstance = null;

function showMessage(e, info, cb) {
  const { target, type } = e;
  const show = type === "mouseenter";

  if (!show) {
    info.classList.remove("shoved");
    // Wait for the fade out animation before destroying the instance
    setTimeout(() => {
      if (!info.classList.contains("shoved")) {
        popperInstance?.destroy();
        popperInstance = null;
      }
    }, 300); // Match this with CSS transition duration
    return;
  }

  info.classList.add("shoved");

  // Create popper instance if it doesn't exist
  if (!popperInstance) {
    popperInstance = createPopper(target, info, {
      placement: "top",
      strategy: "fixed",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8], // [skidding, distance]
          },
        },
        {
          name: "flip",
          options: {
            fallbackPlacements: ["bottom", "right", "left"],
            boundary: "viewport",
            padding: 8,
          },
        },
        {
          name: "preventOverflow",
          options: {
            boundary: "viewport",
            padding: 8,
            altAxis: true,
          },
        },
        {
          name: "computeStyles",
          options: {
            gpuAcceleration: true,
            adaptive: false, // Important! Prevents position recalculation during transition
          },
        },
        {
          name: "arrow",
          options: {
            padding: 5,
          },
        },
      ],
    });
  } else {
    popperInstance.update();
  }

  cb?.();
}

export default showMessage;
