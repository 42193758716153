import { useFeatureIsOn } from "@growthbook/growthbook-react";
import useSWR from "swr";
import store from "/redux/state";

export function useMyParticipations(axiosClient) {
  const flag = useFeatureIsOn("promotions");
  return useSWR(
    () => (flag ? ["user/promotional-campaigns/my-participations"] : null),
    ([url]) => axiosClient.wrap(axiosClient.get(url).then((res) => res)),
    {
      onSuccess: () => {
        store.setLoading(false);
      },
      onError: () => {
        store.setLoading(false);
      },
    }
  );
}
