import { useState } from "react";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash-es";
import { useEffect } from "react";

/**
 * @param {string} nickname
 * @returns {{
 *   isNameOk: boolean,
 *   nameErrors: string|null
 * }}
 */
export function useValidateNickname(nickname) {
  const [isNameOk, setIsNameOk] = useState(true);
  const [nameErrors, setNameErrors] = useState(null);
  const axiosClient = useAxiosWithAuth();
  const { t } = useTranslation();

  const validateName = debounce(async () => {
    if (!nickname?.trim().length) {
      setIsNameOk(true);
      setNameErrors(null);
      return;
    }

    if (nickname?.length < 3 || nickname?.length > 15) {
      setIsNameOk(false);
      setNameErrors(t("registration.errors.usernameLength"));
      return;
    }

    setIsNameOk(false);
    try {
      const sanitizedNickname = nickname.replace(/[<>]/g, "");
      const form = new FormData();
      form.append("nickname", sanitizedNickname);
      const response = await axiosClient.wrap(
        axiosClient.post("verify/nickname", form)
      );

      setNameErrors(response ? t("registration.errors.usernameTaken") : null);
      setIsNameOk(!response);
    } catch (error) {
      switch (error?.message?.toLowerCase()) {
        case "too many attempts.":
          setNameErrors(t("registration.errors.toManyAttempts"));
          break;
        default:
          setNameErrors(t("message.somethingWentWrong"));
          break;
      }
    }
  }, 300);

  useEffect(() => {
    validateName();
    return () => validateName.cancel();
  }, [nickname]);

  return { isNameOk, nameErrors };
}
