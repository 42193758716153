import { Component } from "react";
import FunticoLogo from "./assets/img/logo.svg";
import * as Sentry from "@sentry/react";
import styles from "./ErrorBoundary.module.scss";
import Footer from "./components/layout/Footer";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    // Log to console in development, send to Sentry in other environments
    if (process.env.NODE_ENV === "development") {
      // no need to log this error, it's already logged by react
      // console.error("Error caught by ErrorBoundary:", error, errorInfo);
    } else {
      Sentry.captureException(error, {
        extra: {
          componentStack: errorInfo.componentStack,
        },
      });
    }
  }
  componentDidMount() {
    window.addEventListener("online", this.handleNetworkChange);
  }

  componentWillUnmount() {
    window.removeEventListener("online", this.handleNetworkChange);
  }

  handleNetworkChange = () => {
    if (navigator.onLine && this.state.hasError) {
      window.location.reload();
    }
  };
  handleReturnHome = () => {
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null,
    });

    window.location.href = "/";
  };

  render() {
    if (this.state.hasError) {
      return (
        <>
          <div className={styles.errorContainer}>
            <div className={styles.logoWrapper}>
              <FunticoLogo />
            </div>
            <h1>Oops! Something went wrong</h1>

            <p className="fixel_22">
              We apologize for the inconvenience. The application has
              encountered an unexpected error.
            </p>

            <button
              role="button"
              className="def type-40 br-16 stroke"
              onClick={this.handleReturnHome}
            >
              <span className="txt">Back to Home Page</span>
            </button>
          </div>

          {/* <Footer /> */}
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
