import React, { useEffect } from "react";

import Input from "/components/core/labels/Input";
import Select from "/components/core/labels/Select";
import Checkbox from "/components/core/labels/Checkbox";
import Password from "/components/core/labels/Password";
import Textarea from "/components/core/labels/Textarea";
import Hidden from "/components/core/labels/Hidden";
import { useConversionParams } from "/context/ConversionParamsProvider";
import Calendar from "./Calendar";
const FormLabels = ({
  formData,
  handleChange,
  invalidFields,
  selectData,
  isSuccess,
  def,
  labels,
}) => {
  const { tfid: dMissionUserId } = useConversionParams();

  useEffect(() => {
    return () => {};
  }, [isSuccess]);

  const initLabels = (label) => {
    const obj = {
      error: invalidFields[label.name],
      handleChange: handleChange,
      value: formData[label.name] ?? def?.[label.name] ?? "",
      ...label,
    };

    switch (label.type) {
      case "checkbox":
        return <Checkbox key={label.name} {...obj} />;
      case "password":
        return <Password key={label.name} {...obj} />;
      case "select":
        return selectData ? (
          <Select key={label.name} {...obj} list={selectData} />
        ) : null;
      case "textarea":
        return <Textarea key={label.name} {...obj} max={500} />;
      case "date":
        return <Calendar key={label.name} {...obj} />;
      default:
        return <Input key={label.name} {...obj} />;
    }
  };

  return (
    <>
      {dMissionUserId && <Hidden name="tfid" value={dMissionUserId} />}
      {labels.map((f) => initLabels(f))}
    </>
  );
};

export default FormLabels;
