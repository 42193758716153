import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FormLabels from "/components/core/labels/Form";
import useForm from "/utils/hooks/useForm";
import CustomButton from "/components/core/ButtonDefault";
import Recaptcha from "react-google-invisible-recaptcha";
import { useSearchParams } from "react-router-dom";
import useAxiosREST from "/utils/hooks/useAxiosAPI";
import Hidden from "/components/core/labels/Hidden";
import { useConversionParams } from "/context/ConversionParamsProvider";
import { useLanguages } from "/api/user/language-change";
import { getUnifiedLanguageCode } from "/utils/extractBrowserLanguage";
import i18n from "/i18n/i18n";
import store from "/redux/state";

const siteKey =
  import.meta.env.NODE_ENV == "development"
    ? "6Lfvra8oAAAAAAJR0pNfaCYjJSSA3hcMa5ZabnWD"
    : "6Lc-m_IpAAAAAJWzg5anOMnKpqdW3JQh2wzi3zWk";

const passwordRegex =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).+$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default function RegistrationLabels({ classList = "" }) {
  const { t } = useTranslation();
  const useform = useForm({}, "auth/register");
  const {
    isSuccess,
    isSending,
    handleSubmit,
    setInvalidFields,
    formData,
    formResponse,
    invalidFields,
  } = useform;
  const reCaptcha = useRef(null);
  const { fetchAPI } = useAxiosREST();
  const { data: languages } = useLanguages();

  const { password, passwordAgain, name, email } = formData;

  const [countries, setCountries] = useState(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [detectedLanguage, setDetectedLanguage] = useState(null);
  const [ref_url, setRefUrl] = useState(document.referrer || location.href);
  const formRef = useRef(null);
  const [isPassOk, setIsPassOk] = useState(false);
  const [isNameOk, setIsNameOk] = useState(false);
  const [isEmailOk, setIsEmailOk] = useState(false);
  const [isConfirmPassOk, setIsConfirmPassOk] = useState(false);
  const [referral, setReferral] = useState(null);
  const [initReC, setInitReC] = useState(false);
  const conversionParams = useConversionParams();
  const [connectLuckyFunaticCode, setConnectLuckyFunaticCode] = useState(null);

  const translateErrorCode = (code) => {
    return t(`serverErrorCode.${code}`);
  };

  const labels = [
    {
      placeholder: t("registration.labels.username.placeholder"),
      name: "name",
      limited: true,
      autoComplete: "false",
      description: t("registration.labels.username.description"),
      required: true,
      maxLength: 15,
      isOk: isNameOk,
      minLength: 3,
      tip: {
        title: t("registration.labels.username.placeholder"),
        tip: t("registration.labels.username.tip"),
      },
    },
    {
      placeholder: t("registration.labels.email.placeholder"),
      type: "email",
      name: "email",
      autoComplete: "false",
      required: true,
      spaces: true,
      isOk: isEmailOk,
      tip: {
        title: t("registration.labels.email.title"),
      },
    },
    {
      placeholder: t("registration.labels.password.placeholder"),
      type: "password",
      autoComplete: "false",
      name: "password",
      minLength: 8,
      required: true,
      isOk: isPassOk,
      tip: {
        title: t("registration.labels.password.title"),
        tip: t("registration.labels.password.tip"),
      },
    },
    {
      placeholder: t("registration.labels.passwordConfirm.placeholder"),
      type: "password",
      name: "passwordAgain",
      autoComplete: "false",
      required: true,
      isOk: isConfirmPassOk,
      tip: {
        title: t("registration.labels.passwordConfirm.title"),
      },
    },
    {
      placeholder: t("registration.labels.country.placeholder"),
      type: "select",
      name: "country",
      search: true,
      autoComplete: "false",
      required: true,
      tip: {
        title: t("registration.labels.country.title"),
      },
    },
    {
      title: t("registration.labels.age.title"),
      type: "checkbox",
      name: "older",
    },
    {
      title: t("registration.labels.agree.title"),
      type: "checkbox",
      name: "agree",
    },
    {
      title: t("registration.labels.wishlist.title"),
      type: "checkbox",
      name: "tico_tge_whitelist",
      def: true,
      underline: true,
    },
    {
      title: t("registration.labels.newsletter.title"),
      type: "checkbox",
      name: "is_marketing_communication_allowed",
      def: true,
    },
  ];

  useEffect(() => {
    const luckyFunaticCode = searchParams.get("lf_connect");
    setConnectLuckyFunaticCode(luckyFunaticCode);
    const getCountries = async () => {
      const data = await fetchAPI.get("countries");
      setCountries(data.data);
    };

    const getReferral = () => {
      const btag = searchParams.get("btag") || conversionParams.btag;

      if (btag) {
        setReferral(btag);
        // for (const name of searchParams.keys()) {
        //   // searchParams.delete(name);
        // }
        // setSearchParams("");
      }
    };

    setTimeout(() => setInitReC(true), 5000);

    getReferral();
    getCountries();
    return () => {};
  }, []);

  useEffect(() => {
    setDetectedLanguage(() => {
      const browserLang = getUnifiedLanguageCode(i18n.language);
      if (!languages) {
        return null;
      }

      try {
        return languages.find((lang) => lang.alpha2Code === browserLang);
      } catch {
        return languages.find((lang) => lang.isDefault);
      }
    });
  }, [languages]);

  useEffect(() => {
    if (isSuccess) {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        event: "registration",
      });
    }
    isSuccess &&
      store.setPopUp("success", {
        title: t("registration.success.title"),
        subtitle: t("registration.success.subtitle"),
        close: t("registration.success.close"),
      });
    const refreshTimeout =
      isSuccess &&
      setTimeout(() => {
        i18n.changeLanguage(detectedLanguage.alpha2Code);
        window.location.reload();
      }, 1000);
    return () => {
      clearTimeout(refreshTimeout);
    };
  }, [isSuccess]);

  useEffect(() => {
    if (!password && !passwordAgain) return;

    let st = setTimeout(validatePass, 300);
    return () => {
      clearTimeout(st);
    };
  }, [password, passwordAgain]);

  useEffect(() => {
    if (!name) return;

    setIsNameOk(false);
    let st = setTimeout(validateName, 100);
    return () => {
      clearTimeout(st);
    };
  }, [name]);

  useEffect(() => {
    if (!email) return;
    setIsEmailOk(false);
    let st = setTimeout(validateEmail, 100);
    return () => {
      clearTimeout(st);
    };
  }, [email]);

  useEffect(() => {
    // Case 1: Handle "errors" field with validation error codes (e.g., 422 response)
    if (formResponse?.errors) {
      const translatedErrors = {};

      Object.entries(formResponse.errors).forEach(([field, errors]) => {
        if (Array.isArray(errors)) {
          translatedErrors[field] = errors.map((error) =>
            translateErrorCode(error)
          );
        } else if (typeof errors === "string") {
          translatedErrors[field] = translateErrorCode(errors);
        } else {
          translatedErrors[field] = errors;
        }
      });

      setInvalidFields(translatedErrors);
    }

    // Case 2: Handle general error with error code (e.g., 400 response)
    else if (formResponse && !formResponse.success && formResponse.code) {
      setInvalidFields({
        email: [translateErrorCode(formResponse.code)],
      });
    }
  }, [formResponse]);

  const validatePass = () => {
    let res = { ok: true };
    let err = null;

    if (
      password?.length &&
      passwordAgain?.length &&
      password !== passwordAgain
    ) {
      setIsConfirmPassOk(false);
      err = { passwordAgain: [t("registration.errors.passwordMatch")] };
      res.ok = false;
    } else password && passwordAgain && setIsConfirmPassOk(true);

    if (password?.length < 8 || !passwordRegex.test(password)) {
      setIsPassOk(false);
      err = {
        password: [
          password?.length < 8
            ? t("registration.errors.passwordMin")
            : t("registration.errors.passwordRequirements"),
        ],
      };
      res.ok = false;
    } else password && setIsPassOk(true);
    err && (res.error = err);

    !res.ok && setInvalidFields((e) => ({ ...res.error, ...e }));

    return res.ok;
  };

  const validateName = async () => {
    if (name?.length < 3) {
      setInvalidFields((e) => ({
        name: [t("registration.errors.usernameLength")],
      }));
      return;
    }
    const d = new FormData();
    d.append("nickname", name);
    const data = await fetchAPI.post("verify/nickname", d);
    data &&
      setInvalidFields((e) => ({
        name: t("registration.errors.usernameTaken"),
      }));
    setIsNameOk(!data);
  };

  const validateEmail = async () => {
    const input = formRef.current.querySelector(`[name='email']`);
    const isValid = input.checkValidity() && emailRegex.test(email);

    if (!isValid) {
      setInvalidFields((e) => ({
        ...e,
        ...{ email: [t("registration.errors.emailInvalid")] },
      }));
      return;
    }
    const d = new FormData();
    d.append("email", email);
    const data = await fetchAPI.post("verify/email", d);
    data &&
      setInvalidFields((e) => ({
        ...e,
        ...{ email: [t("registration.errors.emailExists")] },
      }));
    setIsEmailOk(!data);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmitDisabled(true);
    const isOk = validatePass();

    if (isOk && isEmailOk && isNameOk) {
      const data = new FormData(e.target);
      window.regg_mail = data.get("email");
      reCaptcha?.current?.execute().then((res) => {
        data.append("recaptcha", res);
        handleSubmit(data);
      });
    } else {
      let err;
      setInvalidFields((e) => {
        err = e;
        return {};
      });
      setTimeout(() => {
        setInvalidFields(err);
      }, 300);
    }
    setTimeout(() => setIsSubmitDisabled(false), 300);
  };

  return (
    <>
      <form
        className={"form df fd-c" + classList}
        onSubmit={onSubmit}
        ref={formRef}
      >
        <div className="form_content">
          <FormLabels
            {...useform}
            labels={labels}
            selectData={countries}
            def={32}
          />
          {conversionParams.tfid && (
            <Hidden name="dmission_user_id" value={conversionParams.tfid} />
          )}
          {conversionParams.tftarget && (
            <Hidden name="dmission_task_id" value={conversionParams.tftarget} />
          )}
          <Hidden name="referral_url" value={ref_url} />
          {connectLuckyFunaticCode ? (
            <Hidden name="lf_connect" value={connectLuckyFunaticCode} />
          ) : null}
          <Hidden name="page" value={"gaming"} />
          {referral && <Hidden name="btag" value={referral} />}
          {detectedLanguage?.uuid ? (
            <Hidden name="language_id" value={detectedLanguage.uuid} />
          ) : null}
          <Hidden
            name="timezone"
            value={Intl.DateTimeFormat().resolvedOptions().timeZone}
          />
        </div>
        <div className="buttons df mr-t-46">
          <CustomButton
            classname="submit pr as-c "
            def={40}
            type="submit"
            title={t("layout.header.register")}
            disabled={
              isSubmitDisabled ||
              isSending ||
              !useform.formData?.["agree"] ||
              !useform.formData?.["older"]
            }
          />
        </div>
        {Object.keys(useform.invalidFields).length == 1 &&
        useform.invalidFields?.["recaptcha"] ? (
          <div className="error_form mr-t-24 fixel_16_md">
            {useform.invalidFields?.["recaptcha"]}
          </div>
        ) : null}
      </form>

      <div className="hidden_recaptcha">
        {initReC && <Recaptcha ref={reCaptcha} sitekey={siteKey} />}
      </div>
    </>
  );
}
