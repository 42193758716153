import React, { useEffect, useState } from "react";

import { usePopper } from "react-popper";
import { useTranslation } from "react-i18next";
import store from "../../../../redux/state";

// TODO: Extract and refactor this component to be used in other places

export function FiatPurchaseNotPossibleTooltip({
  children,
  triggerEventType = "hover",
  tab,
}) {
  const { t } = useTranslation();
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement] = useState(null);
  const isMobile = store.getState().isMobile;

  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: isMobile ? "bottom" : "right",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
        {
          name: "flip",
          options: {
            // fallbackPlacements: ["bottom", "right"],
            boundary: "clippingParents",
            padding: 8,
          },
        },
        {
          name: "preventOverflow",
          options: {
            boundary: "clippingParents",
            padding: 8,
            altAxis: true,
          },
        },
        {
          name: "computeStyles",
          options: {
            gpuAcceleration: true,
            adaptive: false,
          },
        },
        {
          name: "arrow",
          options: {
            padding: 5,
            element: arrowElement,
          },
        },
        {
          name: "hide",
          enabled: true,
        },
      ],
    }
  );

  const isHover = triggerEventType === "hover";

  const handleShow = () => {
    if (popperElement) {
      popperElement.setAttribute("data-popper-reference-hidden", "false");
      update?.();
    }
  };

  const handleHide = () => {
    if (popperElement) {
      popperElement.setAttribute("data-popper-reference-hidden", "true");
      update?.();
    }
  };

  const handleClick = () => {
    if (popperElement) {
      const currentValue = popperElement.getAttribute(
        "data-popper-reference-hidden"
      );
      popperElement.setAttribute(
        "data-popper-reference-hidden",
        currentValue === "true" ? "false" : "true"
      );
      update?.();
    }
  };

  useEffect(() => {
    if (popperElement) {
      popperElement.setAttribute("data-popper-reference-hidden", "true");
      update?.();
    }
  }, [popperElement, update]);

  let triggerHandlers = {
    onMouseEnter: handleShow,
    onMouseLeave: handleHide,
  };

  if (!isHover) {
    triggerHandlers = {
      onMouseEnter: () => {},
      onMouseLeave: () => {},
      onClick: handleClick,
    };
  }

  return (
    <div style={{ position: "relative" }}>
      <button
        ref={setReferenceElement}
        className="buy-grid--tab df fd-c text-center ai-c br-16 fpnpt-custom-width disabled"
        {...triggerHandlers}
      >
        <div className="df icon cover">
          <img src={tab.src} alt={t(tab.alt)} />
        </div>
        <span
          className="fixel_16_sbl"
          dangerouslySetInnerHTML={{ __html: t(tab.alt) }}
        ></span>
      </button>

      <div
        className="info_message"
        ref={setPopperElement}
        style={{
          ...styles.popper,
          padding: "0px",
          width: isMobile ? "150%" : "auto",
        }}
        {...attributes.popper}
      >
        <div className="wrapper br-8">
          {!isHover ? (
            <button
              type="button"
              className="close_icon close_tooltip_fpnpt"
              onClick={handleClick}
            ></button>
          ) : null}
          {children}
        </div>
      </div>
    </div>
  );
}
